#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}
.valentine-container {
  background-color: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
}
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}
.kiss {
  height: 80%;
  width: 80%;
  border-radius: 60%;
}
.both-Button {
  margin: 10px;
  gap: 10px;
}
.read-the-docs {
  color: #888;
}
.text {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 30px;
  color: yellow;
  font-weight: 500;
  margin: 10px;
}
.NoButton {
  margin: 10px;
  background-color: white;
  color: #000;
}
